
<template>
  <div class="Navigation_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="SitCity">
          <el-menu-item v-for="item in SiteList" :key="item.site_id" :index="item.site_id" :value="item.site_id">{{item.name}}</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：网站导航</div>
    </div>

    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiReset" @click="BtnSearch(false)"><i class="el-icon-refresh"></i>刷新</div>
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="site_id"
        header-cell-class-name="table-header">
        <el-table-column prop="id" label="ID" width="80"  align="center"></el-table-column>
        <el-table-column prop="name" label="导航名称" align="center"></el-table-column>
        <el-table-column prop="url" label="导航链接" align="center" width="400">
          <template #default="scope" >
             <div>
               <el-link class="tttyt" :href='scope.row.url' target="_blank"> <i class="el-icon-paperclip"></i>&nbsp;{{scope.row.url}}</el-link>
             </div>
          </template> 
        </el-table-column>
        <el-table-column prop="intro" label="导航简介" align="center"></el-table-column>
        <el-table-column prop="meta_title" label="SEO标题" align="center"></el-table-column>
        <el-table-column prop="meta_keyword" label="SEO关键字" align="center" width="120"></el-table-column>
        <el-table-column prop="meta_description" label="SEO内容描述" align="center" width="120"></el-table-column>
        <el-table-column prop="status_text" label="状态" align="center"></el-table-column>
        <el-table-column prop="weigh" label="权重" width="80" align="center"></el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template #default="scope">
            <div class="el_butto" >
              <el-button
                  type="text"
                  @click="HandleEdit(scope.row)"
              >编辑
              </el-button>
            </div>
            <div class="el_butto rere" >
              <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(scope.row)"
              >删除
              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </div>


    <div class="show" v-if="addPanel || editPanel">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm">
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.site_id" placeholder="请选择站点" >
                <el-option v-for="item  in SiteList" :key="item.site_id" :value="item.site_id" :label="item.name"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>上级导航</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.pid" placeholder="请选择上级导航">
                <el-option v-for="item in Nav_list" :key="item.pid" :label="item.name" :value="item.pid"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>导航名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.name" placeholder="请填写导航名称" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>简介</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.intro" placeholder="请填写简介" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>跳转链接</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.url" placeholder="请填写跳转链接" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO标题</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.meta_title" placeholder="请填写SEO标题" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO关键字</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.meta_keyword" placeholder="请填写SEO关键字" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>SEO内容描述</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.meta_description" placeholder="请填写SEO内容描述" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName" style="margin-bottom:9px"><span class="signSpan">* </span>状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择上级导航">
                <el-option v-for="(item , key) in TypeList" :key="key" :label="item" :value="key"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重" class="El_ii"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="addPanel == true" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="editPanel == true" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div> 
          </el-form-item>
        </el-form>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue";
export default {
  name: "basetable",
  data() {
    return {
      tableData:[],
      activeIndex:'',
      addPanel:false,
      editPanel:false,
      titleMath:'',
      total: 0,
      editId:'',
      idw:1,
      Hand_id: 0,
      TypeList: [
        "隐藏",
        "正常"
      ],
      search: {
        keyword: "",
        site_id: 1,
      },
      // 分页
      page: {
        currentPage: 1,
        pageSize: 20,
      },
      multipleSelection_id:null,   //勾选的信息
      multipleSelection:[],

      input_seach:'',
      SiteList:[],      //站点列表
      Nav_list:[],      //上级导航
      Row:{
        pid: 0, //上级导航ID
        site_id: 1, //站点ID
        name: "", //导航名称
        url: "", //导航链接
        intro: "", //导航简介
        meta_title: "", //SEO标题
        meta_keyword: "", //SEO标题
        meta_description: "", //SEO内容描述
        status: 1, //启用状态，0=未启用,1=已启用
        weigh: 10 //权重
      }
    };
  },
  mounted() {
    var that = this
    that.loadTable();
    axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
          that.activeIndex = that.SiteList[0].site_id
          that.search.site_id = that.SiteList[0].site_id
        }
    })     
  },
  methods: {
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/general/navigation/index',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        var arr = res.data.data.rows
        var obb = {name:'最上级',pid:0}
        var brr = []
        for (let i = 0; i < arr.length; i++) {
          var obj = {}
          obj.name = arr[i].name
          obj.pid = arr[i].id
          brr.push(obj)
        }
        brr.unshift(obb)
        that.Nav_list = brr
      })
    },
    Close() {
      var that = this
      that.addPanel = false,
      that.editPanel = false
    },
    showAdd() {       //创建
      var that = this
      that.addPanel = true
      that.titleMath = "新建导航"
      that.Row = {}
    },
    //新增
    BtnAdd() {
      var that = this
      if (that.addPanel) {
        axios({
          method: 'post',
          url: '/admin/general/navigation/add',
          data: {
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.addPanel = false
            that.loadTable();
          }
        })
      }
    },
    BtnSearch(e){
      if(e == false){
        this.search.keyword = ''
      }
      this.loadTable()
    },
    HandleDel(data) {
      var that = this
      var arr = []
      //主键ID
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/general/navigation/del',
          data: {
            ids: arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    HandleEdit(data){
      var that = this
      that.editPanel = true;
      that.titleMath = '编辑'
      that.editId = data.id
      // that.Row = data
      axios.get('/admin/general/navigation/read', {   // 详情
        params: {
          id: data.id
        }
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = res.data.data.row
        }
      })
    },
    // 编辑保存
    BtnEdit() {
      var that = this
      if (that.editPanel) {
        axios({
          method: 'put',
          url: '/admin/general/navigation/edit',
          data: {
            id: that.editId,
            row: that.Row
          },
        }).then(function (res) {
          console.log(res.data.data);
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editPanel = false
            that.loadTable();
          }
        })
      }
    },
  },
};
</script>


<style scoped>      

</style>
